<template>
    <HeaderFirst></HeaderFirst>
    <div class="container clearfix" style="margin-top: 15px;">
        <HelpMenu class="page-col-sm"></HelpMenu>
        <div class="page-col-lg">
            <div class="npanel">
                <div class="npanel-heading">
                    <h4>关于我们</h4>
                </div>
                <div class="npanel-body">
                    <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;北京易可诺信息科技有限公司，由从事经济领域数十年的专家团队携手创办，致力于成为引领区域化经济智慧应用、研发中国特色数字经济产品的新型信息科技公司。数据缔造未来。在大数据时代，任何微小的数据都可能产生不可思议的价值。易可诺以“数据融合+实证模型”双线齐行创新思维模式，赋能海量经济数据，构建经济产品体系，为用户提供强有力的智慧化服务。
                    </p>
                </div>
            </div>
        </div>
    </div>
    <FooterNew></FooterNew>
</template>
<script>
import HeaderFirst from '@/components/HeaderFirst.vue'
import HelpMenu from '@/components/HelpMenu.vue'
import FooterNew from '@/components/FooterNew.vue'
//import { ref } from '@vue/reactivity'
export default {
    components:{
        HeaderFirst,
        HelpMenu,
        FooterNew
    },
    setup() {
        
        return{
        
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.page-col-sm {
    position: relative;
    background-color: #fff;
    min-height: 1px;
    float: left;
    width: 200px;
    min-height: 650px;
}
.page-col-lg {
    width: 1048px;
    position: relative;
    overflow: hidden;
}
.npanel {
    border-radius: 4px;
    border: none;
    min-height: 650px;
    background: #fff;
    box-shadow: none;
    border: solid 1px #eee;
    margin-bottom: 15px;
    margin-left: 15px;
}
.npanel-heading {
    color: #333;
    border-bottom:none;
    h4 {
        font-weight: bold;
        margin-top: 0px;
        margin-bottom: 0px;
        display: inline-block;
        line-height: 50px;
        margin-left: 15px;
        font-size: 15px;
    }
}
.npanel-body {
    padding: 20px 15px;
    position: relative;
    &:before{
        content: " ";
        position: absolute;
        height: 1px;
        left: 15px;
        right: 15px;
        top: -1px;
        background: $bordercolor;
        display: block;
        z-index: 2;
    }
    p {
        margin: 0 0 10px;
        line-height: 25px;
    }
}
</style>