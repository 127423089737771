<template>
    <HeaderFirst></HeaderFirst>
    <div class="container clearfix" style="margin-top: 15px;">
        <HelpMenu class="page-col-sm"></HelpMenu>
        <div class="page-col-lg">
            <div class="npanel">
                <div class="npanel-heading">
                    <h4>意见反馈</h4>
                </div>
                <div class="npanel-body">
                    <div class="col-row">
                        <span class="col-pre">
                            问题类别
                        </span>
                        <el-tag
                        v-for="item in items"
                        :key="item.label"
                        class="mx-1"
                        :class="{'active':item.label==selItem}"
                        effect="plain"
                        @click="changeTag(item.label)"
                        >
                        {{ item.label }}
                        </el-tag>
                    </div>
                     <div class="col-row">
                        <span class="col-pre">反馈内容</span> 
                        <el-input
                            v-model="content"
                            :rows="10"
                            type="textarea"
                            placeholder="请描述您的问题，我们及时处理"
                        />
                     </div>
                     <div class="col-row">
                        <span class="col-pre">联系方式</span> 
                        <el-input v-model="contact" placeholder="请输入您的手机号码，方便我们联系您" />
                     </div>
                     <div class="text-center">
                         <el-button type='primary' style="width:200px;">提交</el-button>
                     </div>
                      
                </div>
            </div>
        </div>
    </div>
    <FooterNew></FooterNew>
</template>
<script>
import HeaderFirst from '@/components/HeaderFirst.vue'
import HelpMenu from '@/components/HelpMenu.vue'
import FooterNew from '@/components/FooterNew.vue'
import { ref } from '@vue/reactivity'
export default {
    components:{
        HeaderFirst,
        HelpMenu,
        FooterNew
    },
    setup() {
        const items = ref([
            { label: '功能改进' },
            {  label: '数据问题' },
            {  label: '搜索问题' },
            {  label: '投诉反馈' },
            {label: '其他' },
            ])
        let content=ref('')//反馈内容
        let contact=ref('')//联系方式
        let selItem=ref('')//选中的问题类别
        const changeTag=(val)=>{
            console.log(val);
            selItem.value=val;
            console.log(selItem.value);
        }
        return{
            items,
            content,
            contact,
            selItem,
            changeTag
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.page-col-sm {
    position: relative;
    background-color: #fff;
    min-height: 1px;
    float: left;
    width: 200px;
    min-height: 650px;
}
.page-col-lg {
    width: 1048px;
    position: relative;
    overflow: hidden;
}
.npanel {
    border-radius: 4px;
    border: none;
    min-height: 650px;
    background: #fff;
    box-shadow: none;
    border: solid 1px #eee;
    margin-bottom: 15px;
    margin-left: 15px;
}
.npanel-heading {
    color: #333;
    border-bottom:none;
    h4 {
        font-weight: bold;
        margin-top: 0px;
        margin-bottom: 0px;
        display: inline-block;
        line-height: 50px;
        margin-left: 15px;
        font-size: 15px;
    }
}
.npanel-body {
    padding: 20px 15px;
    position: relative;
    &:before{
        content: " ";
        position: absolute;
        height: 1px;
        left: 15px;
        right: 15px;
        top: -1px;
        background: $bordercolor;
        display: block;
        z-index: 2;
    }
    p {
        margin: 0 0 10px;
        line-height: 25px;
    }
}
.mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    cursor: pointer;
    &:hover{
        background: rgba($themeColor,.05);
    }
    &.active{
        border:1px solid $themeColor;
        background: rgba($themeColor,.1);
    }
}
.col-row{
    margin-bottom: 30px;
}
.col-pre{
    margin-bottom: 10px;
    font-weight: 700;
    display: block;
}
</style>