<template>
    <HeaderFirst></HeaderFirst>
    <div class="container clearfix" style="margin-top: 15px;">
        <HelpMenu class="page-col-sm"></HelpMenu>
        <div class="page-col-lg">
            <div class="npanel">
                <div class="npanel-heading">
                    <h4>用户帮助</h4>
                </div>
                <div class="npanel-body">
                    <p>
                        <strong>
                            <span>
                                1. 经济运行平台的数据来源有哪些？
                            </span>
                        </strong>
                    </p>
                    <p>
                        经济运行平台的企业数据来源于国家统计局、行业年鉴等多家官方网站，并将各类数据统一处理、分类。
                    </p>
                    <p>
                        <strong>
                            <span>
                                2. 经济运行平台提供的企业信息是最新的吗？
                            </span>
                        </strong>
                    </p>
                    <p>
                        经济运行平台提供的经济信息查询会及时更新
                    </p>
                    <p>
                        <strong>
                            <span>
                                3. 注册登录后， 我可以享用哪些功能？
                            </span>
                        </strong>
                    </p>
                    <p>
                        注册登录后，您可以使用更多更强大的功能：
                    </p>
                    <p>
                        一、可以查看地区的经济态势、比较差异
                    </p>
                    <p>
                        二、万级以上数量的宏观经济、行业经济、微观经济指标、经济舆情的查询
                    </p>
                    <p>
                        三、企业基本信息、包含上市公司的数据查询
                    </p>
                    <p>
                        <strong>
                            <span>
                                4. 有哪些渠道我可以直接访问经济运行平台？
                            </span>
                        </strong>
                    </p>
                    <p>
                        1) 您可以直接关注“经济运行平台”微信号（微信号：chinaecono）
                    </p>
                    <p>
                        2) 您可以使用PC登陆经济运行平台Web端 （www.chinaecono.com）
                    </p>
                    <p>
                        <strong>
                            <span>
                                5. 我发现了一个产品BUG，或者找不到想要的查询结果，或者有一个好的建议，应该向谁反馈？
                            </span>
                        </strong>
                    </p>
                    <p>
                        您可以直接联系我们(电话：15324076890)，或者通过页面上的意见反馈对我们进行反馈，我们期待您的反馈和建议。
                    </p>
                </div>
            </div>
        </div>
    </div>
    <FooterNew></FooterNew>
</template>
<script>
import HeaderFirst from '@/components/HeaderFirst.vue'
import HelpMenu from '@/components/HelpMenu.vue'
import FooterNew from '@/components/FooterNew.vue'
//import { ref } from '@vue/reactivity'
export default {
    components:{
        HeaderFirst,
        HelpMenu,
        FooterNew
    },
    setup() {
        
        return{
        
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.page-col-sm {
    position: relative;
    background-color: #fff;
    min-height: 1px;
    float: left;
    width: 200px;
    min-height: 650px;
}
.page-col-lg {
    width: 1048px;
    position: relative;
    overflow: hidden;
}
.npanel {
    border-radius: 4px;
    border: none;
    min-height: 650px;
    background: #fff;
    box-shadow: none;
    border: solid 1px #eee;
    margin-bottom: 15px;
    margin-left: 15px;
}
.npanel-heading {
    color: #333;
    border-bottom:none;
    h4 {
        font-weight: bold;
        margin-top: 0px;
        margin-bottom: 0px;
        display: inline-block;
        line-height: 50px;
        margin-left: 15px;
        font-size: 15px;
    }
}
.npanel-body {
    padding: 20px 15px;
    position: relative;
    &:before{
        content: " ";
        position: absolute;
        height: 1px;
        left: 15px;
        right: 15px;
        top: -1px;
        background: $bordercolor;
        display: block;
        z-index: 2;
    }
    p {
        margin: 0 0 10px;
        line-height: 25px;
    }
}
</style>