<template>
    <Header></Header>
    <div>
        <!-- <div class="auth_header">
            <span style="display:block;text-align:center;color:#e6a23c;font-weight: 600; font-size:36px;letter-spacing: 5px;">权限升级，享超值服务</span>
            <span style="display:block;text-align:center;color:#e6a23c;font-size: 20px;line-height: 40px;">大数据融合,多维度分析</span>
            <div style="text-align:center;">
                <el-popover placement="right" :width="220" trigger="hover">
                        <template #reference>
                            <el-button type="warning" class="btn" round >立即咨询</el-button>
                        </template>
                        <div style="text-align:center;margin:20px;">
                            <el-image class='pic-wechat' src="https://data.chinaecono.com/images/wx_code.jpg?t=1697007893" fit="fill" />
                            
                        </div>
                </el-popover>
            </div>
        </div> -->
        <div class="auth_header">
            <div style="width: 896px;margin: auto;">
                <div style="padding: 20px 0;color: #002FA7;text-align: center;">
                    <div class="vip-title">立即成为VIP</div>
                    <div class="vip-head-info-slogan">
                        <span class="line"></span>
                        <span>更多特权  超值服务</span>
                        <span class="line"></span>
                    </div>
                </div>
                <div class="sku-board">
                    <div class="sku-price-header">
                        选择套餐
                    </div>
                    <div class="sku-price-container ">
                        <div v-for="(item,index) in vipPriceList" :key="item.id" class="sku-price-item " :class="{'sku-price-active':item.id==selVipId}" @click='onChangeVipFun(item.id)'>
                            <span v-if="index==0" class="sku-tag-info">推荐</span>
                            <p class="sku-year-info">{{item.name}}</p>
                            <p class="sku-price-info"><span class="sku-unit">¥</span><span class="sku-price">{{item.price}}</span></p>
                            <span class="mb8 sku-desc-info">每天仅<em>{{item.priceOfDay}}</em>元</span>
                        </div>
                    </div>
                    
                    <div class="vip-btn-content">
                        <div class="vip-explain-item">
                            <p class="vip-explain-title">购买须知</p>
                            <p>1、完成支付后可在个人中心查看支付订单</p>
                            <p>2、可扫页面底部二维码添加客服开发票</p>
                            <p>3、会员自支付完成之时起5分钟内生效</p>
                        </div>
                        <div>
                            <div class="pay-qrcode" v-if='isShowPayQrcode'>
                                <div class="pay-box">
                                    <qrcode-vue :value="payUrl" :size="118" level="H"/>
                                </div>
                                <div class="pay-line">使用 <i class="iconfont ec-icon-wx-pay" style="font-size:14px;color:#1AAD19;"></i> 扫码支付</div>
                            </div>
                            <el-button v-else type="primary" style="font-size: 24px;" @click="onPayFun">立即支付</el-button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
       <div class="pay-center">
        <div style="margin-bottom: 30px;">
            <div class="vip-title">
                <i class="iconfont ec-icon-xinbiaotilan-left" style="font-size: 28px;margin-right:20px;"></i>
                <span>开通VIP，享受专属权益</span>
                <i class="iconfont ec-icon-xinbiaotilan-right" style="font-size: 28px;margin-left:20px;"></i>
            </div>
        </div>
        <el-table :data="authList" border style="width: 100%">
            <el-table-column prop="name" label="服务说明"  class-name="cell-name" label-class-name='cell-name-label'/>
            <el-table-column prop="general" label="普通会员" width="360" class-name="cell-general" label-class-name='cell-general-label'>
                <template #default="scope">
                    <i v-if='scope.row.general=="1" || scope.row.general=="0"' :class="scope.row.general=='1'?'iconfont ec-icon-duigou':'iconfont ec-icon-close'"></i>
                    <span v-else>{{scope.row.general}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="vip" label="VIP会员" width="360" class-name="cell-vip" label-class-name='cell-vip-label'>
                <template #default="scope">
                    <i v-if='scope.row.vip=="1" || scope.row.vip=="0"' :class="scope.row.vip=='1'?'iconfont ec-icon-duigou':'iconfont ec-icon-close'"></i>
                    <span v-else>{{scope.row.vip}}</span>
                </template>
            </el-table-column>
        </el-table>
       </div>
       <el-dialog v-model="dialogPayVisible" :close-on-click-modal='false' title="添加微信" :width="400" @close="onCloseDialogFun">
            <div class="LSb1iW3M">
                <div style="color: rgba(30, 32, 35, .45);text-align:center;">
                    感谢您选择我们的产品,请添加微信，我们将为您提供即时的售后支持和专业的技术支持。
                </div>
                <div class="JBWR88_b">
                    <el-image style="width:186px; height:186px" src="https://data.chinaecono.com/images/wx_code.jpg" fit="fill" />
                    <div>微信扫码</div>
                </div>
            </div>
            <!-- <div style="text-align:center;">
                    <div style="margin-bottom:20px;">
                        <span>金额总计：</span> 
                        <span class="unit">¥</span>
                        <span class="price" style="font-size: 26px;">{{vipPriceList.find(t=>t.id==selVipId)?.price}}</span>
                    </div>
                    <qrcode-vue :value="payUrl" :size="120" level="H"/>
                    <div style="display: flex;align-items: center;justify-content: center;margin-top:10px;">
                        <i class="iconfont ec-icon-saoma" style="font-size:30px;color:#ff7d18;"></i>
                        <span>
                            <span>打开手机微信</span>
                            <br>
                            <span>扫一扫付款</span>
                        </span>
                    </div>
            </div> -->
        </el-dialog>
        <!-- <div class="flexrcc" style="margin-top: 100px;margin-left: 88px;margin-right: 40px;margin-bottom: 90px;">
            <div class="flexcsc vip_item" v-for="item in authList" :key="item.name">
                <div class="flexrcc vip_name" :style="{'background':item.color}">{{item.name}}</div>
                <div class="vip_quota" v-for=' el in item.list' :key='el.title'>
                    <div class="vip_quota_title">{{el.title}}</div>
                    <div class="flexrbc vip_quota_item" v-for="obj in el.item" :key="obj.name">
                        <div>{{obj.name}}</div>
                        <div>{{obj.opt}}</div>
                    </div>
                </div>
            </div> 
        </div> -->
    </div>
    <div class="app-nfooter"></div>
    <FooterNew></FooterNew>
</template>
<script>
import Header from '@/components/Header.vue'
import FooterNew from '@/components/FooterNew.vue'
import { reactive,ref } from '@vue/reactivity';
import {getVIPPriceList,createPayOrder,getVipOrder,addUserLog} from '@/http/basicsApi'
import {parseTime} from '@/utils/index'
import QrcodeVue from 'qrcode.vue';
import router from '@/router/index.js'
import { onUnmounted } from 'vue';
export default {
    components:{
        Header,
        FooterNew,
        QrcodeVue
    },
    setup() {
        /*
        感谢您选择我们的产品,请添加我们的微信，这样我们可以更直接地回答您的疑问，并提供必要的技术支持。
        let authList=reactive([{name:'大众会员',color:'#F5F5F5',list:[{title:'区域态势',item:[{name:'通用指标',opt:'查看'},{name:'个性指标',opt:'不可用'},{name:'地区比较',opt:'不可用'},{name:'指标收藏',opt:'0项'},{name:'区域分析报告',opt:'不可用'}]},
                                                    {title:'行业趋势',item:[{name:'行业趋势分析',opt:'查看'},{name:'行业分析报告',opt:'不可用'}]},
                                                    {title:'企业画像',item:[{name:'企业画像',opt:'查看'},{name:'企业画像报告',opt:'不可用'}]},
                                                    {title:'指标搜索',item:[{name:'指标查询',opt:'查看'},{name:'指标收藏',opt:'不可用'},{name:'指标下载',opt:'不可用'}]},
                                                    {title:'应用',item:[{name:'产经智链',opt:'不可用'}]},
                                                    {title:'用户功能',item:[{name:'收藏空间',opt:'0项'},{name:'数据导出',opt:'0项'},{name:'报告订阅',opt:'无'}]},
                                                    {title:'其他',item:[{name:'售后支持',opt:'无'},{name:'纸质版报告邮寄',opt:'无'},{name:'经济沙龙',opt:'无'}]}]},
                                {name:'银会员',color:'#C0C0C0',list:[{title:'区域态势',item:[{name:'通用指标',opt:'查看'},{name:'个性指标',opt:'可编辑'},{name:'地区比较',opt:'可用'},{name:'指标收藏',opt:'100项'},{name:'区域分析报告',opt:'pdf'}]},
                                                    {title:'行业趋势',item:[{name:'行业趋势分析',opt:'查看'},{name:'行业分析报告',opt:'20份/天'}]},
                                                    {title:'企业画像',item:[{name:'企业画像',opt:'查看'},{name:'企业画像报告',opt:'20家/天'}]},
                                                    {title:'指标搜索',item:[{name:'指标查询',opt:'查看'},{name:'指标收藏',opt:'100项'},{name:'指标下载',opt:'50项/天'}]},
                                                    {title:'应用',item:[{name:'产经智链',opt:'查看'}]},
                                                    {title:'用户功能',item:[{name:'收藏空间',opt:'100项'},{name:'数据导出',opt:'50项/天'},{name:'报告订阅',opt:'无'}]},
                                                    {title:'其他',item:[{name:'售后支持',opt:'工单支持'},{name:'纸质版报告邮寄',opt:'无'},{name:'经济沙龙',opt:'无'}]}]},
                                {name:'金会员',color:'#DAA520',list:[{title:'区域态势',item:[{name:'通用指标',opt:'查看'},{name:'个性指标',opt:'可编辑'},{name:'地区比较',opt:'可用'},{name:'指标收藏',opt:'不限'},{name:'区域分析报告',opt:'pdf'}]},
                                                    {title:'行业趋势',item:[{name:'行业趋势分析',opt:'查看'},{name:'行业分析报告',opt:'100份/天'}]},
                                                    {title:'企业画像',item:[{name:'企业画像',opt:'标签编辑'},{name:'企业画像报告',opt:'100家/天'}]},
                                                    {title:'指标搜索',item:[{name:'指标查询',opt:'查看'},{name:'指标收藏',opt:'不限'},{name:'指标下载',opt:'200项/天'}]},
                                                    {title:'应用',item:[{name:'产经智链',opt:'查看'}]},
                                                    {title:'用户功能',item:[{name:'收藏空间',opt:'不限'},{name:'数据导出',opt:'200项/天'},{name:'报告订阅',opt:'支持'}]},
                                                    {title:'其他',item:[{name:'售后支持',opt:'微信支持'},{name:'纸质版报告邮寄',opt:'无'},{name:'经济沙龙',opt:'线上'}]}]},
                                {name:'钻石会员',color:'#D2691E',list:[{title:'区域态势',item:[{name:'通用指标',opt:'参与讨论'},{name:'个性指标',opt:'可编辑'},{name:'地区比较',opt:'可用'},{name:'指标收藏',opt:'不限'},{name:'区域分析报告',opt:'pdf/word'}]},
                                                    {title:'行业趋势',item:[{name:'行业趋势分析',opt:'查看'},{name:'行业分析报告',opt:'不限'}]},
                                                    {title:'企业画像',item:[{name:'企业画像',opt:'标签编辑'},{name:'企业画像报告',opt:'不限'}]},
                                                    {title:'指标搜索',item:[{name:'指标查询',opt:'查看'},{name:'指标收藏',opt:'不限'},{name:'指标下载',opt:'500项/天'}]},
                                                    {title:'应用',item:[{name:'产经智链',opt:'查看及企业推荐'}]},
                                                    {title:'用户功能',item:[{name:'收藏空间',opt:'不限'},{name:'数据导出',opt:'500项/天'},{name:'报告订阅',opt:'支持'}]},
                                                    {title:'其他',item:[{name:'售后支持',opt:'微信支持'},{name:'纸质版报告邮寄',opt:'支持'},{name:'经济沙龙',opt:'线上+线下'}]}]}])
        */
       let authList=reactive([{name:'区域态势-通用',general:"1",vip:"1"},
        {name:'区域态势-个性',general:"0",vip:"1"},
        {name:'地区比较',general:"0",vip:"1"},
        {name:'指标收藏',general:"0",vip:"1"},
        {name:'区域分析报告',general:"0",vip:"1"},
        {name:'行业趋势分析',general:"1",vip:"1"},
        {name:'行业分析报告',general:"0",vip:"1"},
        {name:'企业画像',general:"1",vip:"1"},
        {name:'企业画像报告',general:"0",vip:"1"},
        {name:'指标搜索',general:"1",vip:"1"},
        {name:'指标收藏',general:"0",vip:"1"},
        {name:'指标下载',general:"0",vip:"1"},
        {name:'产经智链',general:"0",vip:"1"},
        {name:'研报通',general:"0",vip:"1"},
        {name:'收藏空间',general:"0",vip:"1"},
        {name:'数据导出',general:"0",vip:"1"},
        {name:'报告订阅',general:"0",vip:'支持'},
        {name:'售后支持',general:"0",vip:'微信支持'},
        {name:'纸质版报告邮寄',general:"0",vip:'支持'},
        {name:'经济沙龙',general:"0",vip:'线上'}])
        let vipPriceList=ref([]);//vip的价格列表
        let selVipId=ref(0);//选中vipId
        const getVIPPriceListFun= async()=>{
            let res=await getVIPPriceList();
            console.log(res);
            if(res.code==20000){
                vipPriceList.value=res.data;
                
                vipPriceList.value.forEach(t=>{
                    t.priceOfDay=Math.round(t.price/t.dayCount * 100) / 100 ;
                    let date=new Date();
                    t.deadline=parseTime(date.setDate(date.getDate() + t.dayCount),'{y}-{m}-{d}')
                })
                selVipId.value=vipPriceList.value[0].id;
            }
        }
        //选择套餐
        const onChangeVipFun=(id)=>{
            selVipId.value=id;
            isShowPayQrcode.value=false;
            if(timer){//停止循环
                clearInterval(timer);
                timer=null;
            }
        }
        //支付
        let payUrl=ref('');//支付url
        
        let isShowPayQrcode=ref(false);//是否显示支付二维码
        let timer=null;
        let isPay=ref(false);//是否支付
        const onPayFun=async()=>{
            //调用Native下单API生成预支付交易以获取支付二维码链接code_url
            let res=await createPayOrder(selVipId.value);
            console.log(res);
            if(res.code==20000){
                //打开支付对话框，用户扫描支付二维码
                payUrl.value=res.data.codeUrl;
                let orderCode=res.data.orderCode;
                isShowPayQrcode.value=true;
                //循环请求查看是否支付成功
                timer = setInterval(() => {
                    getVipOrderFun(orderCode);
                }, 1000);
            }
            //记录用户操作日志
            addUserLog(router.currentRoute.value.name,router.currentRoute.value.fullPath,'支付',`{"selVipId":${selVipId.value}}`);
        }
        
        //查询订单信息
        let dialogPayVisible=ref(false);//支付后的显示添加微信的对话框
        const getVipOrderFun= async(orderCode)=>{
            let params={
                orderCode:orderCode,
                pageSize:1,
                pageIndex:1
            }
            let res=await getVipOrder(params);
            if(res.code==20000 && res.data.totalCount>0){
                isPay.value=res.data.dataList[0].state==20?true:false;
                if(isPay.value){//停止循环
                    clearInterval(timer);
                    timer=null;
                    dialogPayVisible.value=true;
                    //跳转到首页
                    //router.push('/home');
                }
            }
        }
        //对话框关闭
        const onCloseDialogFun=()=>{
            //跳转到首页
            router.push('/home');
        }
        //页面卸载
        onUnmounted(()=>{
            if(timer){//停止循环
                clearInterval(timer);
                timer=null;
            }
        })
        //加载
        getVIPPriceListFun();
        
        return{
            authList,
            vipPriceList,
            selVipId,
            onChangeVipFun,
            onPayFun,
            isShowPayQrcode,
            dialogPayVisible,
            payUrl,
            isPay,
            onCloseDialogFun
        }
    },
}
</script>
<style lang='scss' scoped>
@import '@/styles/variables.scss';
.auth_header{
    height: 400px;
    background-color: #128bed;
    background-image: url('../../assets/vip-bg.png');
}
/*
.ecRjRa{
    width: 60%;
    top: 50%;
    margin-top: -60px;
    background: #fff;
    margin-left: 20%;
    height: 120px;
    border-radius: 10px;
    box-shadow: 0 12px 20px 0 rgb(55 67 100 / 8%);
   
    padding:10px 50px ;
}
.flexrcc {
    display: flex;
    justify-content: center;
    align-items: center;
}
.flexcsc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.flexrbc {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.vip_item {
    padding-bottom: 40px;
    margin-right: 48px;
    background: #fff;
    border: 2px solid #fff;
    border-radius: 16px;
    box-sizing: border-box;
    width: 25%;
}
.vip_name {
    font-size: 18px;
    font-weight: 600;
    font-size: 1.5rem;
    width: 100%;
    height: 120px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
}
.vip_quota {
    margin-top: 40px;
    width: 100%;
    padding: 0 30px;
}
.vip_quota_title {
    font-size: 16px;
    font-weight: 600;
    color: #171717;
}
.vip_quota_item {
    margin-top: 10px;
    
    color: #8f92a1;
}
.vip_tel {
    color:$specialtext-color;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 2px;
}
.btn{
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 5px;
    color: rgba(112,63,11,1);
    background-image: linear-gradient(to right, rgb(255,226,167),rgb(231,169,95));
    margin-top: 30px;
}
*/




.vip-head-info-slogan {
    font-size: 18px;

    line-height: 26px;
    margin-bottom: 2px;
}
.vip-head-info-slogan .line {
    display: inline-block;
    width: 60px;
    height: 1px;
    background-color: #b7cff6;
    vertical-align: middle;
    position: relative;
    margin-left: 5px;
    margin-right: 5px;
    top: -2px;
}
 .sku-board {
    background-color: #fff;
    margin: 0 auto;
    border-radius: 4px;
}
.sku-board .sku-price-header {
    font-size: 14px;
    font-weight: bolder;
    line-height: 18px;
    padding-left: 12px;
    padding-top: 12px;
}


.vip-explain-item {
    border-radius: 4px;
    padding: 16px  12px;
    color:#999;
    p{
        margin: 3px;
    }
}
.vip-explain-title {
    color: #333;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 14px;
}
.vip-btn-content {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 24px;
    border-top: 1px solid #e6e6e6;
    
}
.pay-qrcode{
    width: 130px;
    background: #fff;
    position: relative;
}
 .pay-box {
    width: 130px;
    height: 130px;
    padding: 6px;
    display: inline-block;
    position: relative;
    border:1px solid #e6e6e6
}
.pay-line {
    text-align: center;
    font-size: 12px;
    background-color: #e6e6e6;
    line-height: 20px;
}
.text {
    color: #333;
    font-size: 20px;
    line-height: 24px;
    font-weight: bolder;
}
.unit {
    color: #ff7d18;
    font-size: 20px;
    line-height: 24px;
    margin-right: 2px;
    font-weight: bolder;
}
.price {
    color: #ff7d18;
    font-size: 36px;
    font-weight: bolder;
    line-height: 28px;
}
.vip-pay-qr{
    display: flex;
    gap: 0 10px;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 24px;
}
@media (min-width: 1281px){
    .pay-center {
    margin-left: calc(50vw - 625px);
}}
.pay-center {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    width: 1250px;
    margin-top:210px;
    text-align: center;
}
.vip-title {
    font-size: 30px;
    font-weight: bold;
    line-height: 42px;
    color: #002FA7;
}

:deep(.el-table .cell-general){
    background-color: #f3f3f3;
    color: #999;
    text-align: center;
}

:deep(.el-table .cell-vip){
    background-color: rgba(210, 105, 30, 0.2);
    color: rgba(210, 105, 30, 1);
    text-align: center;
}
:deep(.el-table .cell-name){
    background-color: #f7f7f7;
}
:deep(.el-table .cell-general-label),:deep(.el-table .cell-vip-label),:deep(.el-table .cell-name-label){
    font-size: 20px;
    font-weight: 700;
    height: 60px;
}
.app-nfooter {
    margin-top: 50px;
}
</style>