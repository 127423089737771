<template>
    <HeaderFirst></HeaderFirst>
    <div class="container clearfix" style="margin-top: 15px;">
        <HelpMenu class="page-col-sm"></HelpMenu>
        <div class="page-col-lg">
            <div class="npanel">
                <div class="npanel-heading">
                    <h4>联系我们</h4>
                </div>
                <div class="npanel-body">
                    <img src="@/assets/adress.png" alt="" style="width:1000px;">
                    <p style="font-weight: 600;">
                        电话：
                    </p>
                    <p>
                        15324076890
                    </p>
                    <p style="font-weight: 600;">
                        邮箱：
                    </p>
                    <p>
                    market@chinaecono.com
                    </p>
                    <p style="font-weight: 600;">
                    地址：
                    </p>
                    <p>
                        北京市海淀区中关村大街1号17层M22号
                    </p>
                    
                </div>
            </div>
        </div>
    </div>
    <FooterNew></FooterNew>
</template>
<script>
import HeaderFirst from '@/components/HeaderFirst.vue'
import HelpMenu from '@/components/HelpMenu.vue'
import FooterNew from '@/components/FooterNew.vue'
//import { ref } from '@vue/reactivity'
export default {
    components:{
        HeaderFirst,
        HelpMenu,
        FooterNew
    },
    setup() {
        
        return{
        
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.page-col-sm {
    position: relative;
    background-color: #fff;
    min-height: 1px;
    float: left;
    width: 200px;
    min-height: 650px;
}
.page-col-lg {
    width: 1048px;
    position: relative;
    overflow: hidden;
}
.npanel {
    border-radius: 4px;
    border: none;
    min-height: 650px;
    background: #fff;
    box-shadow: none;
    border: solid 1px #eee;
    margin-bottom: 15px;
    margin-left: 15px;
}
.npanel-heading {
    color: #333;
    border-bottom:none;
    h4 {
        font-weight: bold;
        margin-top: 0px;
        margin-bottom: 0px;
        display: inline-block;
        line-height: 50px;
        margin-left: 15px;
        font-size: 15px;
    }
}
.npanel-body {
    padding: 20px 15px;
    position: relative;
    &:before{
        content: " ";
        position: absolute;
        height: 1px;
        left: 15px;
        right: 15px;
        top: -1px;
        background: $bordercolor;
        display: block;
        z-index: 2;
    }
    p {
        margin: 0 0 10px;
        line-height: 25px;
    }
}
</style>