<template>
    <HeaderFirst></HeaderFirst>
    <div class="container clearfix" style="margin-top: 15px;">
        <HelpMenu class="page-col-sm"></HelpMenu>
        <div class="page-col-lg">
            <div class="npanel">
                <div class="npanel-heading">
                    <h4>免责声明</h4>
                </div>
                <div class="npanel-body">
                    <p>
                        “经济运行平台”是由北京易可诺信息科技有限公司开发的一款经济相关数据等公开信息查询工具。根据用户指令， 经济运行平台的搜索引擎系统会以非人工检索方式生成用户检索的相关经济信息。经济运行平台目前数据来源网站主要包括：
                    </p>
                    <ul class="knowledge-box">
                        <li >国家统计局</li> 
                        <li >经济年鉴</li> 
                        <li>行业年鉴</li> 
                        <li>人社部</li> 
                        <li>教育部</li> 
                        <li>住建部</li> 
                        <li>交通部</li> 
                        <li>民政部</li> 
                        <li>水利部</li> 
                        <li>财政部</li> 
                        <li>土地市场网</li>
                        <li>海关总署</li> 
                    </ul>
                    <p>
                        经济运行平台所登载的信息来自于网络公开等信息，经济运行平台仅对相关信息向用户如实展示， 并不主动编辑或修改被所公示网站上的信息的内容或其表现形式。 
                        受限于现有技术水平、各信息来源网站更新不同步等原因，对此类信息的展示， 并不视为经济运行平台对其内容的真实性、准确性、完整性、时效性作出任何形式的确认或担保。 
                        请您在依据经济运行平台服务相关信息作出判断或决策前，自行进一步核实此类信息的完整或准确性， 并自行承担使用后果。
                        同时，经济运行平台承诺将不断提升技术水平，逐步完善服务信息来源质量与更新频次，为您提供更高质量的服务。
                    </p>
                </div>
            </div>
        </div>
    </div>
    <FooterNew></FooterNew>
</template>
<script>
import HeaderFirst from '@/components/HeaderFirst.vue'
import HelpMenu from '@/components/HelpMenu.vue'
import FooterNew from '@/components/FooterNew.vue'
//import { ref } from '@vue/reactivity'
export default {
    components:{
        HeaderFirst,
        HelpMenu,
        FooterNew
    },
    setup() {
        
        return{
        
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.page-col-sm {
    position: relative;
    background-color: #fff;
    min-height: 1px;
    float: left;
    width: 200px;
    min-height: 650px;
}
.page-col-lg {
    width: 1048px;
    position: relative;
    overflow: hidden;
}
.npanel {
    border-radius: 4px;
    border: none;
    min-height: 650px;
    background: #fff;
    box-shadow: none;
    border: solid 1px #eee;
    margin-bottom: 15px;
    margin-left: 15px;
}
.npanel-heading {
    color: #333;
    border-bottom:none;
    h4 {
        font-weight: bold;
        margin-top: 0px;
        margin-bottom: 0px;
        display: inline-block;
        line-height: 50px;
        margin-left: 15px;
        font-size: 15px;
    }
}
.npanel-body {
    padding: 20px 15px;
    position: relative;
    &:before{
        content: " ";
        position: absolute;
        height: 1px;
        left: 15px;
        right: 15px;
        top: -1px;
        background: $bordercolor;
        display: block;
        z-index: 2;
    }
    p {
        margin: 0 0 10px;
        line-height: 25px;
    }
}
.knowledge-box{
    margin:15px 0; 
    padding-left:40px;
    list-style:disc;
    li{
        list-style: disc;
    }
}
</style>